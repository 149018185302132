<template>
    <layout :classes="classes" :routeName="routeName">
        <div class="row">
            <div class="content">
                <div class="form-container">
                    <div class="title-container">
                        <h3 class="heading small">{{$t('authentication.forgotPassword.title')}}</h3>
                    </div>
                    <div class="form login">
                        <div class="form-content">
                            <div v-if="showSuccess">
                                <p class="form-success"><font-awesome-icon :icon="['fa', 'circle-check']" />{{$t('authentication.forgotPassword.thanks')}}</p>
                            </div>

                            <div v-else>
                                <div class="input-container">
                                    <label :class="error('email')">{{$t('login.form.email.label')}}</label>
                                    <input :class="error('email')" v-model="data.email" type="text" :placeholder="$t('login.form.email.placeholder')" @keyup.enter="forgotPassword()">

                                    <FormError :data="validation.email" />
                                </div>
                                
                                <button class="cta cta-primary submit" :class="{ loading: loading }" @click="forgotPassword()">{{$t('authentication.forgotPassword.label')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lb-bg"></div>

            <img v-if="$screen.width <= 768" class="bottom-bg mobile" src="../../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
            <img v-else class="bottom-bg desktop" src="../../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
        </div>
    </layout>
</template>

<script>
import Layout from "../../../components/Layout.vue";
import FormError from "../../../components/Utils/FormError.vue"

export default {
    name: "ForgotPassword",

    components: {
        Layout,
        FormError
    },

    data() {
        return {
            routeName: "forgot-password",

            classes: {
                login: true
            },

            data: {
                email: '',
            },

            validation: {
                email: {
                    error: false,
                    key: 'errors.validation.emailInvalid'
                }
            },

            formValidated: false,
            loading: false,
            showSuccess: false,

            //eslint-disable-next-line
            emailRegex:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },

    methods: {
        redirect: function() {
            this.$utils._navigate_to_name('login');
        },

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false;

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(!self.emailRegex.test(self.data.email))
                self.validation.email.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        forgotPassword: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;
                const data = {
                    email: self.data.email,
                };

                self.$axios
                    .post('/password/forgot', data)
                    .then(response => {
                        self.loading = false;
                        
                        if(response.status == 200) {
                            self.showSuccess = true;
                            
                            setTimeout(self.redirect, 3500);
                        }
                    })
                    .catch(() => {
                        self.loading = false;
                        self.showSuccess = true;

                        setTimeout(self.redirect, 3500);
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },
    }
};
</script>